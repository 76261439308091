<template>
  <div>
    <template v-if="detailProducts.length === 0 && detailCircuits.length === 0">

      <!-- #region::Alert when no circuits are available -->
      <b-alert
        v-if="!editionModeState"
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>No se agregaron productos a la cotización.</span>
        </div>
      </b-alert>
      <!-- #endregion::Alert when no circuits are available -->

    </template>

    <template v-else-if="detailProducts.length > 0">
      <b-form-row>

        <!-- #region::Title -->
        <b-col md="12">
          <h3>Productos</h3>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Products list -->
        <b-col>
          <app-collapse
            accordion
            type="margin"
          >
            <RefundProductCollapsableItem
              v-for="product in detailProducts"
              :key="product.IdProduct"
              :product="product"
              :selectable="selectable"
              :show-edit-button="false"
              :show-delete-button="editionModeState"
              :is-visible="false"
              :show-refund-tag="showRefundTag"
              :disabled-form-components="!editionModeState"
              :show-pieces-list-actions-buttons="editionModeState"
            />
          </app-collapse>
        </b-col>
        <!-- #endregion::Products list -->

      </b-form-row>
    </template>
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { BCol, BFormRow, BAlert } from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import buildProduct from '@/helpers/ProductConverter'
// #endregion

export default {
  name: 'OrderProductDetails',
  components: {
    BCol,
    BAlert,
    BFormRow,
    AppCollapse,
    RefundProductCollapsableItem: () => import('@/modules/store/refunds/components/cards/RefundProductCollapsableItem.vue'),
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    showRefundTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingData: true,
    }
  },
  computed: {
    ...mapGetters({
      getRefund: 'refunds/getRefund',
      getDetailCircuits: 'orders/getDetailCircuits',
      getDetailProducts: 'orders/getDetailProducts',
      getEditionModeState: 'orders/getEditionModeState',
    }),
    /**
     * Información de la devolución
     */
    refund: {
      get() { return this.getRefund },
    },
    detailProducts: {
      get() { return this.getDetailProducts },
    },
    detailCircuits: {
      get() { return this.getDetailCircuits },
    },
    editionModeState: {
      get() { return this.getEditionModeState },
    },
  },
  watch: {
    order() {
      this.buildProductsList()
    },
  },
  created() {
    this.buildProductsList()
  },
  methods: {
    ...mapActions({
      clearData: 'orders/clearData',
      addDetailProduct: 'orders/addDetailProduct',
    }),
    buildProductsList() {
      // Limpiar la lista de productos
      this.clearData()

      const orderProducts = this.refund.products
      orderProducts.forEach(quoteProduct => {
        const product = buildProduct(quoteProduct)

        const detailProduct = { ...product }
        detailProduct.pieces = product.pieces.map(piece => ({ ...piece }))
        this.$set(detailProduct, 'unitPrice', product.UnitPrice)
        this.addDetailProduct(detailProduct)
      })
    },
  },
}
</script>
